'use client';
/* eslint-disable no-unused-labels */
/* eslint-disable no-undef */
import Image from 'next/image';
import React, { useState } from 'react';
const buttonStyle = {
  position: 'fixed',
  bottom: '13px',
  right: '21px',
  background: '#c30b55',
  padding: '.7rem 1.21rem',
  borderRadius: '999rem',
  fontSize: '1.07143rem',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  zIndex: 2
};

const disabledStyle = {
  display: 'none'
};

const imgStyle = {
  paddingRight: '.57143rem',
  width: '30px',
  height: '20px',
  verticalAlign: 'middle'
};

const spanStyle = {
  color: 'white',
  fontWeight: '700',
  fontSize: '1rem',
  letterSpacing: '.6',
  verticalAlign: 'middle'
};

const ZenDeskLoaderButton = () => {
  let [disabled, setDisabled] = useState(false);

  function loadZenDesk(e) {
    setDisabled(true);
    e.preventDefault();
    e.currentTarget.classList.add('disable');
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=e33fb74f-e7b6-4d76-81f1-295973cda0f2';
    document.getElementsByTagName('body')[0].appendChild(script);

    script.addEventListener(
      'load',
      function () {
        setTimeout(function () {
          javascript: $zopim.livechat.window.show();
        }, 500);
      },
      { passive: true }
    );
  }

  return (
    <button
      style={{ ...(disabled ? { ...buttonStyle, ...disabledStyle } : buttonStyle) }}
      onClick={(e) => {
        loadZenDesk(e);
      }}>
      <Image
        style={imgStyle}
        src="/static/images/icons/chat.svg"
        alt="Zendesk support icon"
        width={30}
        height={20}
        unoptimized
        priority
      />
      <span style={spanStyle}>Help</span>
    </button>
  );
};

export default ZenDeskLoaderButton;
