export default function contentfulLoader({ src, width, quality }) {
  if (src?.includes('images.ctfassets.net')) {
    const url = new URL(`${src}`);
    url.searchParams.set('fm', 'webp');
    url.searchParams.set('w', width.toString());
    url.searchParams.set('q', (quality || 90).toString());
    return url.href;
  }
  return src;
}
